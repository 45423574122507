<template>
  <section class="wrapper__business-withdraws-list p-4">
    <h4 class="h4 font-semi-bold d-inline-block">Retiros</h4>
    <h4 class="h4 font-bold float-right text-right" v-if="showTotalWithdraws">
      <small class="text-color-black d-block">Total retirado</small>
      <FormatAmount :amount="totalWithdraws" />
    </h4>

    <section class="input-filters my-3">
      <InputSearchComponent @onChangeSearch="onChangeSearch" />
      <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
      <FilterBetweenDatesSelect
        v-if="!setWithdraws"
        @onAsyncDataForDates="onAsyncDataForDates"
      />
    </section>

    <LoadingSkeletonTable v-if="toggleApiRequest" />

    <template v-else>
      <template v-if="withdraws.length">
        <paginate
          class="wrapper-pagination"
          name="resultQueryWithdraws"
          :list="resultQueryWithdraws"
          :per="perPaginate"
          :key="searchQuery"
        >
          <el-table
            :data="paginated('resultQueryWithdraws')"
            stripe
            @row-click="onClickWithdrawView"
          >
            <el-table-column prop="description" label="Descripción" width="250">
            </el-table-column>
            <el-table-column label="Monto">
              <template slot-scope="scope">
                <FormatAmount :amount="scope.row.amount" />
              </template>
            </el-table-column>
            <el-table-column label="Estatus">
              <template slot-scope="scope">
                <IsPaid :isPaid="scope.row.is_paid" />
              </template>
            </el-table-column>
            <el-table-column prop="ref" label="Referencia"> </el-table-column>

            <el-table-column prop="created_at" label="Fecha"> </el-table-column>
          </el-table>
        </paginate>
        <paginate-links
          v-show="resultQueryWithdraws.length > perPaginate"
          for="resultQueryWithdraws"
          :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
          :simple="{
            next: 'Siguiente »',
            prev: '« Regresar'
          }"
        ></paginate-links>
      </template>
      <EmptyData v-else />
    </template>
    <WrapperDrawer
      :onVisible.sync="drawerWithdrawView"
      @onCloseDrawer="onCloseDrawerWithdrawView"
    >
      <WithdrawView :withdraw.sync="withdrawView" />
    </WrapperDrawer>
  </section>
</template>

<script>
import { icoCheck } from "@/services/resources";
import InputSearchComponent from "@/modules/dashboard/components/InputSearch";
import SelectOrderByComponent from "@/modules/dashboard/components/SelectOrderBy";
import FilterBetweenDatesSelect from "@/modules/dashboard/components/FilterBetweenDatesSelect";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import EmptyData from "@/components/General/EmptyData";
import FormatAmount from "@/components/General/FormatAmount";
import WrapperDrawer from "@/components/General/WrapperDrawer";
import WithdrawView from "./WithdrawView";
import IsPaid from "@/components/General/IsPaid";
import businessService from "@/modules/dashboard/profile/pages/business/services/index";

export default {
  name: "WithdrawList",
  data() {
    return {
      icoCheck,
      toggleApiRequest: false,
      withdraws: [],
      searchFilters: {},
      searchQuery: null,
      orderByQuery: null,
      perPaginate: 10,
      paginate: ["resultQueryWithdraws"],
      withdrawView: null,
      drawerWithdrawView: false
    };
  },
  computed: {
    resultQueryWithdraws() {
      let withdraws = this.withdraws;
      return withdraws
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.description.toLowerCase() < b.description.toLowerCase()
                ? -1
                : 1;
            case "z-a":
              return a.description.toLowerCase() > b.description.toLowerCase()
                ? -1
                : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.description
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.ref.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    },
    totalWithdraws() {
      let amount = 0;
      this.withdraws.forEach(e => (amount += e.amount));
      return amount;
    },
    requestFilters() {
      const { from = null, to = null } = this.searchFilters;
      return { from, to };
    }
  },
  methods: {
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    onAsyncDataForDates({ searchDates }) {
      this.updatedSearchFilters(searchDates);
      this.withdraws = [];
      this.getBusinessWithdraws();
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };
    },
    onCloseDrawerWithdrawView() {
      this.drawerWithdrawView = false;
    },
    onClickWithdrawView(withdraw) {
      this.withdrawView = withdraw;
      this.drawerWithdrawView = true;
    },
    async getBusinessWithdraws() {
      try {
        this.toggleApiRequest = true;
        let response = await businessService
          .getBusinessWithdraws({
            params: {
              ...this.searchFilters
            }
          })
          .finally(() => {
            this.toggleApiRequest = false;
          });
        const { data, success } = response.data;
        if (success) {
          this.withdraws = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    EmptyData,
    FormatAmount,
    FilterBetweenDatesSelect,
    WrapperDrawer,
    WithdrawView,
    IsPaid
  },
  props: {
    setWithdraws: {
      default: null
    },
    showTotalWithdraws: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    if (!this.setWithdraws) {
      this.getBusinessWithdraws();
    } else {
      this.withdraws = this.setWithdraws;
    }
  }
};
</script>

<style lang="scss" scoped></style>
