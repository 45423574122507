<template>
  <section class="wrapper__business-withdraw-add p-4">
    <b-row>
      <b-col xs="12" lg="12">
        <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <h4 class="h4 font-semi-bold mt-3 mb-4 text-center">
            Solicitud de retiro de fondos
          </h4>
        </div>
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="amount" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Monto</label>
            </slot>
            <InputAmount
              :amount="form.amount"
              @onChange="onChangeInputAmount"
            />
          </el-form-item>

          <el-form-item prop="bank_account_id" class="mb-4">
            <slot name="label">
              <div class="d-flex justify-content-between align-items-center">
                <label class="font-semi-bold m-0">Cuenta a transferir</label>
                <LinkPlusComponent
                  onlyIcon
                  @onClick="onAddBankAccount"
                  class="mr-2"
                />
              </div>
            </slot>

            <el-select
              placeholder="Seleccionar"
              class=""
              v-model="form.bank_account_id"
            >
              <el-option
                v-for="account in bankAccounts"
                :key="account.id"
                :label="account.name"
                v-model="account.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="description" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Descripción</label>
            </slot>
            <el-input
              placeholder=""
              class=""
              v-model="form.description"
              rows="10"
            ></el-input>
          </el-form-item>

          <b-row>
            <b-col xs="12" sm="12">
              <ButtonPrimary
                class="center mt-4"
                title="Guardar"
                @onClick="handleSaveClick"
              />
            </b-col>
          </b-row>
        </el-form>
      </b-col>
    </b-row>

    <el-dialog
      :visible.sync="dialogAddBankAccount"
      append-to-body
      :before-close="handleCloseAddBankAccount"
    >
      <BankAccountAdd @onBankAccountAdd="onBankAccountAdd" />
    </el-dialog>
  </section>
</template>

<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import InputAmount from "@/components/General/InputAmount";
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import BankAccountAdd from "@/modules/dashboard/profile/pages/business/components/BankAccount/BankAccountAdd";
import Validate from "@/assets/validate";
import businessService from "@/modules/dashboard/profile/pages/business/services/index";

export default {
  name: "WithdrawAdd",
  data() {
    return {
      bankAccounts: [],
      dialogAddBankAccount: false,
      form: {
        amount: null,
        description: null,
        bank_account_id: null
      },
      rules: {
        amount: [Validate.rules.required()],
        bank_account_id: [Validate.rules.required("change")]
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    onChangeInputAmount({ amount }) {
      this.form.amount = amount;
    },
    async getBankAccounts() {
      try {
        let response = await businessService.getBankAccounts().finally(() => {
          this.toggleApiRequest = false;
        });
        const { data, success } = response.data;
        if (success) {
          this.bankAccounts = data;
        }
      } catch (error) {
        return false;
      }
    },
    onAddBankAccount() {
      this.dialogAddBankAccount = true;
    },
    handleCloseAddBankAccount() {
      this.dialogAddBankAccount = false;
    },
    onBankAccountAdd({ bankAccount }) {
      this.bankAccounts = [...[bankAccount], ...this.bankAccounts];
      this.handleCloseAddBankAccount();
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await businessService
              .addBusinessWithdraws(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, data, message } = response.data;
            if (success) {
              this.$emit("onWithdrawAdd", {
                withdraw: data
              });
              this.$notifications.success({
                message
              });

              this.$refs.form.resetFields();
            }
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  components: {
    ButtonPrimary,
    InputAmount,
    LinkPlusComponent,
    BankAccountAdd
  },
  props: {},
  mounted() {
    this.getBankAccounts();
  }
};
</script>

<style lang="scss" scoped></style>
