<template>
  <section class="">
    <TopBarTitle title="Wallet" :showBackIcon="true">
      <template slot="right-area">
        <div class=" d-flex justify-content-end align-items-center">
          <ButtonPrimary
            title="Solicitar retiro"
            @onClick="onClickWithdrawAdd"
          />
        </div>
      </template>
    </TopBarTitle>
    <b-container fluid class="pb-4 px-lg-4">
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3">
          <div class="list-tabs-left">
            <el-tabs
              v-model="activeTabsBalance"
              tab-position="left"
              style="height: 200px;"
              @tab-click="handleTabsEarnings"
            >
              <el-tab-pane label="Ingresos" name="earnings"></el-tab-pane>
              <el-tab-pane label="Retiros" name="withdraws"></el-tab-pane>
              <el-tab-pane
                label="Transacciones"
                name="transactions"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </b-col>
        <b-col xs="12" sm="9" md="9" lg="9">
          <b-row>
            <b-col xs="12" sm="4" md="4" class="mb-2">
              <div
                class="border-radius md__border mb-2 p-3 bg-color-primary h-100"
              >
                <div class="wrapper__number-statistics">
                  <div class="wrapper__number-statistics-header">
                    <div class="d-flex justify-content-between w-100">
                      <div class="bg-icon-rounded wallet">
                        <img :src="icoWallet" alt="wallet" width="24" />
                      </div>
                    </div>
                  </div>
                  <div class="wrapper__number-statistics-body">
                    <h1 class="h1 text-color-secondary">
                      <FormatAmount :amount="totalBalance" abbreviate />
                    </h1>
                    <p class="m-0 text-color-write">
                      Balance <br />
                      Total
                    </p>
                  </div>
                  <div class="wrapper__number-statistics-footer"></div>
                </div>
              </div>
            </b-col>
            <b-col xs="12" sm="4" md="4" class="mb-2">
              <div
                class="border-radius md__border mb-2 p-3 bg-color-write h-100"
              >
                <div class="wrapper__number-statistics">
                  <div class="wrapper__number-statistics-header">
                    <div class="d-flex justify-content-between w-100">
                      <div class="bg-icon-rounded check">
                        <img :src="icoCheck" alt="check" width="24" />
                      </div>
                      <FilterBetweenDates
                        @onAsyncDataForDates="onAsyncDataForDatesEarnings"
                      />
                    </div>
                  </div>
                  <div class="wrapper__number-statistics-body">
                    <h1 class="h1">
                      <FormatAmount :amount="totalNetIncome" abbreviate />
                    </h1>
                    <p class="m-0 text-color-secondary">
                      Total <br />
                      Ingresos
                    </p>
                  </div>
                  <div class="wrapper__number-statistics-footer mt-2">
                    <p class="text-color-primary m-0">
                      <strong>
                        Total sin comisiones &nbsp;
                        <span>
                          <FormatAmount :amount="totalIncome" />
                        </span>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col xs="12" sm="4" md="4" class="mb-2">
              <div
                class="border-radius md__border mb-2 p-3 bg-color-write h-100"
              >
                <div class="wrapper__number-statistics">
                  <div class="wrapper__number-statistics-header">
                    <div class="d-flex justify-content-between w-100">
                      <div class="bg-icon-rounded check">
                        <img :src="icoCheck" alt="check" width="24" />
                      </div>
                      <FilterBetweenDates
                        @onAsyncDataForDates="onAsyncDataForDatesWithdraws"
                      />
                    </div>
                  </div>
                  <div class="wrapper__number-statistics-body">
                    <h1 class="h1">
                      <FormatAmount :amount="totalWithdraw" abbreviate />
                    </h1>
                    <p class="m-0 text-color-secondary">
                      Total <br />
                      Retiros
                    </p>
                  </div>
                  <div class="wrapper__number-statistics-footer"></div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="box-shadow border-radius p-3 mb-3 bg-color-write mt-4">
            <EarningList
              showTotalEarnings
              v-if="activeTabsBalance === 'earnings'"
            />

            <WithdrawList
              showTotalWithdraws
              v-if="activeTabsBalance === 'withdraws'"
            />

            <TransactionList v-if="activeTabsBalance === 'transactions'" />
          </div>
        </b-col>
      </b-row>
      <WrapperDrawer
        :onVisible.sync="drawerWithdrawAdd"
        @onCloseDrawer="onCloseDrawerWithdrawAdd"
      >
        <WithdrawAdd @onWithdrawAdd="onWithdrawAdd" />
      </WrapperDrawer>
    </b-container>
    
  </section>
</template>

<script>
import { icoCheck, icoWallet } from "@/services/resources";
import TopBarTitle from "@/components/General/TopBarTitle";
import EarningList from "./components/EarningList";
import WithdrawList from "./components/WithdrawList";
import TransactionList from "./components/TransactionList";
import FormatAmount from "@/components/General/FormatAmount";
import FilterBetweenDates from "../../dashboard/components/FilterBetweenDates";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import WrapperDrawer from "@/components/General/WrapperDrawer";
import WithdrawAdd from "./components/WithdrawAdd";

import businessService from "@/modules/dashboard/profile/pages/business/services/index";

export default {
  name: "BalanceView",
  data() {
    return {
      icoCheck,
      icoWallet,
      withdraws: [],
      activeTabsBalance: "earnings",
      totalWithdraw: 0,
      totalNetIncome: 0,
      totalIncome: 0,
      totalBalance: 0,
      drawerWithdrawAdd: false
    };
  },
  computed: {},
  methods: {
    handleTabsEarnings(tab) {
      this.activeTabsBalance = tab.name;
    },
    onAsyncDataForDatesWithdraws({ searchDates }) {
      this.getReportsTotalWithdraws({ searchDates });
    },
    onAsyncDataForDatesEarnings({ searchDates }) {
      this.getReportsTotalEarnings({ searchDates });
    },
    onUpdateWithdrawAdd({ amount = 0 }) {
      this.totalBalance = this.totalBalance - amount;
    },
    onClickWithdrawAdd() {
      this.drawerWithdrawAdd = true;
    },
    onCloseDrawerWithdrawAdd() {
      this.drawerWithdrawAdd = false;
    },
    onWithdrawAdd({ withdraw }) {
      this.withdraws = [...[withdraw], ...this.withdraws];
      this.onCloseDrawerWithdrawAdd();
      this.onUpdateWithdrawAdd(withdraw);
    },
    async getReportsTotalBalance({ searchDates }) {
      try {
        let response = await businessService
          .getReportsTotalBalance({
            params: {
              ...searchDates
            }
          })
          .finally(() => {});
        const { data, success } = response.data;
        if (success) {
          this.totalBalance = data;
        }
      } catch (error) {
        return false;
      }
    },
    async getReportsTotalEarnings({ searchDates }) {
      try {
        let response = await businessService
          .getReportsTotalEarnings({
            params: {
              ...searchDates
            }
          })
          .finally(() => {});
        const { data, success } = response.data;
        if (success) {
          const { total_net_income, total_income } = data;
          this.totalNetIncome = total_net_income;
          this.totalIncome = total_income;
        }
      } catch (error) {
        return false;
      }
    },
    async getReportsTotalWithdraws({ searchDates }) {
      try {
        let response = await businessService
          .getReportsTotalWithdraws({
            params: {
              ...searchDates
            }
          })
          .finally(() => {});
        const { data, success } = response.data;
        if (success) {
          this.totalWithdraw = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitle,
    EarningList,
    WithdrawList,
    TransactionList,
    FormatAmount,
    FilterBetweenDates,
    ButtonPrimary,
    WrapperDrawer,
    WithdrawAdd
  },
  mounted() {
    this.getReportsTotalWithdraws({});
    this.getReportsTotalEarnings({});
    this.getReportsTotalBalance({});
  }
};
</script>

<style lang="scss" scoped></style>
