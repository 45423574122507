<template>
  <section class="wrapper__business-transactions-list p-4">
    <h4 class="h4 font-semi-bold d-inline-block">Transacciones</h4>

    <section class="input-filters my-3">
      <InputSearchComponent @onChangeSearch="onChangeSearch" />
      <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
      <FilterBetweenDatesSelect
        v-if="!setTransactions"
        @onAsyncDataForDates="onAsyncDataForDates"
      />
    </section>

    <LoadingSkeletonTable v-if="toggleApiRequest" />

    <template v-else>
      <template v-if="transactions.length">
        <paginate
          class="wrapper-pagination mt-4"
          name="resultQueryTransactions"
          :list="resultQueryTransactions"
          :per="perPaginate"
          :key="searchQuery"
        >
          <el-table
            :data="paginated('resultQueryTransactions')"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="description" label="Descripción" width="250">
            </el-table-column>
            <el-table-column
              prop="trans_df_id"
              label="ID Transacción"
              width="280"
            ></el-table-column>
            <el-table-column
              prop="trans_df_result_description"
              label="Mensaje red"
            >
            </el-table-column>
            <el-table-column prop="ref" label="Referencia"> </el-table-column>
            <el-table-column label="Monto">
              <template slot-scope="scope">
                <FormatAmount :amount="scope.row.amount" />
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="Fecha"> </el-table-column>
          </el-table>
        </paginate>
        <paginate-links
          v-show="resultQueryTransactions.length > perPaginate"
          for="resultQueryTransactions"
          :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
          :simple="{
            next: 'Siguiente »',
            prev: '« Regresar'
          }"
        ></paginate-links>
      </template>
      <EmptyData v-else />
    </template>
    <WrapperDrawer
      :onVisible.sync="drawerTransactionView"
      @onCloseDrawer="onCloseDrawerTransactionView"
    >
    </WrapperDrawer>
  </section>
</template>

<script>
import InputSearchComponent from "@/modules/dashboard/components/InputSearch";
import SelectOrderByComponent from "@/modules/dashboard/components/SelectOrderBy";
import FilterBetweenDatesSelect from "@/modules/dashboard/components/FilterBetweenDatesSelect";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import EmptyData from "@/components/General/EmptyData";
import WrapperDrawer from "@/components/General/WrapperDrawer";
import FormatAmount from "@/components/General/FormatAmount";

import businessService from "@/modules/dashboard/profile/pages/business/services/index";

export default {
  name: "TransactionList",
  data() {
    return {
      toggleApiRequest: false,
      transactions: [],
      searchFilters: {},
      searchQuery: null,
      orderByQuery: null,
      perPaginate: 10,
      paginate: ["resultQueryTransactions"],
      transactionView: null,
      drawerTransactionView: false
    };
  },
  computed: {
    resultQueryTransactions() {
      let transactions = this.transactions;
      return transactions
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.description.toLowerCase() < b.description.toLowerCase()
                ? -1
                : 1;
            case "z-a":
              return a.description.toLowerCase() > b.description.toLowerCase()
                ? -1
                : 1;
          }
        })
        .filter(item =>
          this.searchQuery
            ? item.description
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.ref.toLowerCase().includes(this.searchQuery.toLowerCase())
            : item
        );
    },
    requestFilters() {
      const { from = null, to = null } = this.searchFilters;
      return { from, to };
    }
  },
  methods: {
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    onAsyncDataForDates({ searchDates }) {
      this.updatedSearchFilters(searchDates);
      this.transactions = [];
      this.getBusinessTransactions();
    },
    updatedSearchFilters(filters) {
      this.searchFilters = {
        ...this.searchFilters,
        ...filters
      };
    },
    onCloseDrawerTransactionView() {
      this.drawerTransactionView = false;
    },
    onClickTransactionView(withdraw) {
      this.transactionView = withdraw;
      this.drawerTransactionView = true;
    },
    tableRowClassName({ row }) {
      if (row?.amount < 0) {
        return "error-row";
      }
      return "";
    },
    async getBusinessTransactions() {
      try {
        this.toggleApiRequest = true;
        let response = await businessService
          .getBusinessTransactions({
            params: {
              ...this.searchFilters
            }
          })
          .finally(() => {
            this.toggleApiRequest = false;
          });
        const { data, success } = response.data;
        if (success) {
          this.transactions = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    EmptyData,
    FilterBetweenDatesSelect,
    WrapperDrawer,
    FormatAmount
  },
  props: {
    setTransactions: {
      default: null
    }
  },
  mounted() {
    if (!this.setTransactions) {
      this.getBusinessTransactions();
    } else {
      this.withdraws = this.setTransactions;
    }
  }
};
</script>

<style lang="scss" scoped></style>
