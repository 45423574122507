<template>
  <el-tag
    type="default"
    :class="
      `status ${
        isPaid ? 'success' : 'pending'
      } border-radius text-capitalize font-bold`
    "
    >{{ status }}</el-tag
  >
</template>

<script>
export default {
  name: "IsPaid",
  computed: {
    status() {
      return this.isPaid ? "Pagada" : "Pendiente";
    }
  },
  props: {
    isPaid: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style></style>
