<template>
  <section class="wrapper__business-withdraw-view p-4" v-if="withdraw">
    <h4 class="h4 font-semi-bold">
      <a href="#" class="text-color-primary" @click="onWithdrawListBack"
        ><i class="el-icon-back font-bold"></i
      ></a>
      Información retiro
    </h4>

    <b-row class="my-4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <b-row>
          <b-col xs="12" lg="12">
            <label for="" class="font-bold">Descripción</label>
            <p class="">{{ withdraw.description }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Monto</label>
            <p class="font-bold text-color-secondary">
              <FormatAmount :amount="withdraw.amount" />
            </p>
          </b-col>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Referencia</label>
            <p class="">{{ withdraw.ref }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Fecha</label>
            <p class="">{{ withdraw.created_at }}</p>
          </b-col>
          <!-- <b-col xs="12" lg="6">
            <label for="" class="font-bold">Estatus</label>
            <p class=""></p>
          </b-col> -->
        </b-row>
      </b-col>
    </b-row>

    <h5 class="h5 font-semi-bold text-color-black">
      Cuenta bancaria
    </h5>

    <b-row class="my-4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <b-row>
          <b-col xs="12" lg="12">
            <label for="" class="font-bold">Enviar fondos</label>
            <p class="">
              {{ withdraw.bank_account_name }}
              {{ withdraw.bank_account_account_str }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <template>
      <h5 class="h5 font-semi-bold text-color-black">
        Información adicional
      </h5>
      <b-row class="my-4">
        <b-col xs="12" lg="6">
          <label for="" class="font-bold">Estatus</label>
          <p class="">
            <IsPaid :isPaid="withdraw.is_paid" />
          </p>
        </b-col>
        <b-col xs="12" lg="6">
          <label for="" class="font-bold">Descripción pago</label>
          <p class="">{{ withdraw.description_paid }}</p>
        </b-col>
      </b-row>
    </template>
  </section>
</template>

<script>
import FormatAmount from "@/components/General/FormatAmount";
import IsPaid from "@/components/General/IsPaid";

export default {
  name: "WithdrawView",
  data() {
    return {};
  },
  computed: {},
  methods: {
    onWithdrawListBack() {}
  },
  components: {
    FormatAmount,
    IsPaid
  },
  props: {
    withdraw: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped></style>
